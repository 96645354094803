var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('span',{staticClass:"text-h6"},[_vm._v(_vm._s(_vm.$t('message.specifications')))]),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.Salesitems,"calculate-widths":"","dense":"","disable-pagination":"","disable-sort":"","hide-default-header":"","hide-default-footer":"","item-key":"index"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var headers = ref.props.headers;
return [_c('tr',[_c('th',{staticClass:"grey lighten-3 text-left width-1-pct font-xs"},[_vm._v(_vm._s(headers[0].text))]),_c('th',{staticClass:"grey lighten-3 text-left font-xs"},[_vm._v(_vm._s(headers[1].text))]),_c('th',{staticClass:"grey lighten-3 text-left font-xs"},[_vm._v(_vm._s(headers[2].text))]),_c('th',{staticClass:"grey lighten-3 text-left font-xs"},[_vm._v(_vm._s(headers[3].text))]),_c('th',{staticClass:"grey lighten-3 text-left font-xs"},[_vm._v(_vm._s(headers[4].text))]),_c('th',{staticClass:"grey lighten-3 text-left font-xs text-no-wrap"},[_vm._v(_vm._s(headers[5].text))]),_c('th',{staticClass:"grey lighten-3 text-left font-xs text-no-wrap"},[_vm._v(_vm._s(headers[6].text))])])]}},{key:"item.lineNumber",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-center"},[_vm._v(_vm._s(item.lineNumber))])]}},{key:"item.Salesdescription.title",fn:function(ref){
var item = ref.item;
return [(item.type == 'default')?_c('div',[_vm._v(_vm._s(item.Salesdescription.title + ' ' + item.Size.title))]):_vm._e(),(item.type == 'othercharge')?_c('div',[_vm._v(_vm._s(item.Salesitem.size))]):_vm._e()]}},{key:"item.Grade.title",fn:function(ref){
var item = ref.item;
return [(item.type == 'default')?_c('div',[_vm._v(_vm._s(item.Grade.title))]):_vm._e()]}},{key:"item.Salesitem.itemqty",fn:function(ref){
var item = ref.item;
return [(item.type == 'default')?_c('div',[_vm._v(_vm._s(_vm.formatThisNumber(item.Salesitem.itemqty, _vm.getUofMFormatPrecision(item.Salesitem.measurement_id))))]):_vm._e()]}},{key:"item.Salesitem.unitprice",fn:function(ref){
var item = ref.item;
return [(item.type == 'default')?_c('div',[_vm._v(_vm._s(_vm.formatThisNumber(item.Salesitem.unitprice, _vm.allCurrencies.find(function (currency){ return currency.Currency.id === _vm.Contract__currency_id; }).Currency.formatPrecision)))]):_vm._e(),(item.type == 'othercharge')?_c('div',[_c('v-text-field',{staticClass:"custom pa-0 mt-0 bg-lightgrey",attrs:{"placeholder":_vm.$t('message.volAndQty'),"value":item.Salesitem.unitprice,"autocomplete":"off","dense":"","hide-details":"auto","type":"number"},on:{"change":function($event){return _vm.updateSalesItemRowHandle( item.index, 'Salesitem.unitprice',$event)}}})],1):_vm._e()]}},{key:"item.Salesitem.invqty",fn:function(ref){
var item = ref.item;
return [(item.type == 'default')?_c('div',[(item.type == 'default')?_c('v-text-field',{staticClass:"custom pa-0 mt-0 bg-lightgrey",attrs:{"placeholder":_vm.$t('message.volAndQty'),"value":item.Salesitem.invqty,"autocomplete":"off","dense":"","hide-details":"auto","type":"number"},on:{"change":function($event){return _vm.updateSalesItemRowHandle( item.index, 'Salesitem.invqty',$event)}}}):_vm._e()],1):_vm._e()]}},{key:"item.Salesitem.invprice",fn:function(ref){
var item = ref.item;
return [(item.type == 'default')?_c('div',[_c('v-text-field',{staticClass:"custom pa-0 mt-0 bg-lightgrey",attrs:{"placeholder":_vm.$t('message.price'),"value":item.Salesitem.invprice,"autocomplete":"off","dense":"","hide-details":"auto","type":"number"},on:{"change":function($event){return _vm.updateSalesItemRowHandle( item.index, 'Salesitem.invprice',$event)}}})],1):_vm._e(),(item.type == 'othercharge')?_c('div',[_vm._v(_vm._s(_vm.formatThisNumber(item.Salesitem.unitprice, _vm.allCurrencies.find(function (currency){ return currency.Currency.id === _vm.Contract__currency_id; }).Currency.formatPrecision)))]):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }