<template>
	<div>
		<span class="text-h6">{{ $t('message.specifications') }}</span>
		<v-data-table
			:headers="headers"
			:items="Salesitems"
			calculate-widths
			dense
			disable-pagination
			disable-sort
			hide-default-header
			hide-default-footer
			item-key="index"
		>
			<template v-slot:header="{ props: { headers } }">
				<tr>
					<th class="grey lighten-3 text-left width-1-pct font-xs">{{ headers[0].text }}</th>
					<th class="grey lighten-3 text-left font-xs">{{ headers[1].text }}</th>
					<th class="grey lighten-3 text-left font-xs">{{ headers[2].text }}</th>
					<th class="grey lighten-3 text-left font-xs">{{ headers[3].text }}</th>
					<th class="grey lighten-3 text-left font-xs">{{ headers[4].text }}</th>
					<th class="grey lighten-3 text-left font-xs text-no-wrap">{{ headers[5].text }}</th>
					<th class="grey lighten-3 text-left font-xs text-no-wrap">{{ headers[6].text }}</th>
				</tr>
			</template>
			<template v-slot:item.lineNumber="{ item }">
				<div class="text-center">{{ item.lineNumber }}</div>
			</template>
			<template v-slot:item.Salesdescription.title="{ item }">
				<div v-if="item.type == 'default'">{{ item.Salesdescription.title + ' ' + item.Size.title }}</div>
                <div v-if="item.type == 'othercharge'">{{ item.Salesitem.size }}</div>
			</template>
			<template v-slot:item.Grade.title="{ item }">
				<div v-if="item.type == 'default'">{{ item.Grade.title }}</div>
			</template>
			<template v-slot:item.Salesitem.itemqty="{ item }">
				<div v-if="item.type == 'default'">{{ formatThisNumber(item.Salesitem.itemqty, getUofMFormatPrecision(item.Salesitem.measurement_id)) }}</div>
			</template>
			<template v-slot:item.Salesitem.unitprice="{ item }">
				<div v-if="item.type == 'default'">{{ formatThisNumber(item.Salesitem.unitprice, allCurrencies.find((currency)=>currency.Currency.id === Contract__currency_id).Currency.formatPrecision) }}</div>
                <div v-if="item.type == 'othercharge'">
                    <v-text-field
                        :placeholder="$t('message.volAndQty')"
                        :value="item.Salesitem.unitprice"
                        autocomplete="off"
                        class="custom pa-0 mt-0 bg-lightgrey"
                        dense
                        hide-details="auto"
                        type="number"
                        @change="updateSalesItemRowHandle( item.index, 'Salesitem.unitprice',$event)"
                    >
                    </v-text-field>
                </div>
            </template>
			<template v-slot:item.Salesitem.invqty="{item}">
                <div v-if="item.type == 'default'">
                    <v-text-field
                        :placeholder="$t('message.volAndQty')"
                        :value="item.Salesitem.invqty"
                        autocomplete="off"
                        class="custom pa-0 mt-0 bg-lightgrey"
                        dense
                        hide-details="auto"
                        type="number"
                        v-if="item.type == 'default'"
                        @change="updateSalesItemRowHandle( item.index, 'Salesitem.invqty',$event)"
                    >
                    </v-text-field>
                </div>
			</template>
			<template v-slot:item.Salesitem.invprice="{ item }">
                <div v-if="item.type == 'default'">
                    <v-text-field
                        :placeholder="$t('message.price')"
                        :value="item.Salesitem.invprice"
                        autocomplete="off"
                        class="custom pa-0 mt-0 bg-lightgrey"
                        dense
                        hide-details="auto"
                        type="number"
                        @change="updateSalesItemRowHandle( item.index, 'Salesitem.invprice',$event)"
                    >
                    </v-text-field>
                </div>
                <div v-if="item.type == 'othercharge'">{{ formatThisNumber(item.Salesitem.unitprice, allCurrencies.find((currency)=>currency.Currency.id === Contract__currency_id).Currency.formatPrecision) }}</div>
			</template>
		</v-data-table>
	</div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
	import {mapFields, mapMultiRowFields} from 'vuex-map-fields'
	import {numberFormat} from "../../helpers/helpers";
	export default {
		name: "SplitContractSalesItemList",
		data(){
			return {}
		},
		computed: {
			...mapFields('contract',{
				Contract__currency_id: 'partial.Contract.currency_id',
			}),
            ...mapGetters('currency',{
                allCurrencies: 'allCurrencies'
            }),
			...mapGetters([
				'currencies',
				'uofmQty'
			]),
			...mapMultiRowFields('contract',{
				Salesitems: 'partial.Salesitems'
			}),
			currencyFormatPrecision () {
				return this.allCurrencies.find((currency) => currency.Currency.id === this.Contract__currency_id)?.Currency?.formatPrecision
			},
			headers () {
				let headers = [
					{
						id: 0,
						text: this.$t('message.lineNo'),
						value: 'lineNumber'
					},
					{
						id: 1,
						text: this.$t('message.speciesSpecSize'),
						value: 'Salesdescription.title'
					},
					{
						id: 2,
						text: this.$t('message.grade'),
						value: 'Grade.title'
					},
					{
						id: 3,
						text: this.$t('message.volAndQty'),
						value: 'Salesitem.itemqty'
					},
					{
						id: 4,
						text: this.$t('message.shippedVolAndQty'),
						value: 'Salesitem.invqty'
					},
					{
						id: 5,
						text: this.$t('message.unitPrice'),
						value: 'Salesitem.unitprice'
					},
					{
						id: 6,
						text: this.$t('message.shippedUnitPrice'),
						value: 'Salesitem.invprice'
					},
				]
				return headers
			}
		},
		methods: {
            ...mapActions('currency', {
                getAllCurrencies: 'getAllCurrencies'
            }),
			formatThisNumber(value,format){
				return numberFormat(value,format)
			},
            getUofMFormatPrecision(val){
                let format = this.uofmQty.find( uofm => uofm.Measurement.id == val )
                if(format){
                    return format.Measurement.formatPrecision
                } else {
                    return '0,0'
                }
            },
			updateSalesItemRowHandle(idx,field,value) {
				let payload = {
					index: idx,
					field: field,
					value: value,
				}
				let promise = new Promise((resolve, reject) => {
					this.$store.commit('contract/updatePartialContractSalesItems', payload)
					resolve('ok')
				});
			}
		},
        created() {
            if(this.allCurrencies.length == 0) this.getAllCurrencies()
        }
    }
</script>

<style>
    .bg-lightgrey {
        background-color: #F5F5F5;
    }
    .v-data-table tbody td {
        vertical-align: center !important;
    }
	table td.text-start {
		padding-left: 3px !important;
		padding-right: 2px !important;
	}
</style>